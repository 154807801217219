import "../scss/main.scss";
import "slick-carousel";

$(function () {

    var adminBar;
    if ($("#wpadminbar")) {
        adminBar = $("#wpadminbar").innerHeight();
    } else {
        adminBar = 0;
    }

    $("#up").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, "slow");
        return false;
    });

    $("#down").on("click", function () {
        $("html, body").animate(
            { scrollTop: $("h1").offset().top - $("#masthead").innerHeight() * 2 },
            "slow"
        );
        return false;
    });

    $("#btNav").on("click", function () {
        $(this).toggleClass("open");
        $(".col--navigation-mobile").toggleClass("open");
    });

    $(".bt-search").on("click", function () {
        $("#search").toggleClass("opensearch");
    });

    // $('a[href^="#"]').each(function(){
    //     $(this).on('click', function (e) {
    //         e.preventDefault();

    //         var target = this.hash;

    //         $('html, body').animate({scrollTop: $(target).offset().top - $('#masthead').innerHeight() - 64}, 900, 'swing');
    //         return false;
    //     });
    // });

    actionsScroll();
    $(window).on("scroll", function () {
        actionsScroll();
    });

    actionsResize();
    $(window).on("resize", function () {
      actionsResize();
    });

    // INIT ALL FUNCTIONS
    imgBack();
    sliders();
    if ($(".accordeon").length != 0) {
      accordeon();
    }
    if ($(".tabs").length != 0) {
      onglets();
    }
    animationScroll();
});

function actionsScroll() {
  $(".site-search").removeClass("active");

  var scrolled = $(window).scrollTop();

  if (scrolled > 0) {
    $("body").addClass("scrolling");
  } else {
    $("body").removeClass("scrolling");
  }
}

function actionsResize() {
  $("main").css("padding-top", $("#masthead").innerHeight());

  $("#margin-dynamic").css(
    "margin-top",
    -$("#margin-dynamic-resize").innerHeight() * 0.5 +
      $("#margin-dynamic-resize-less").innerHeight()
  );

  sliderNumbers();

  // Hauteurs des produits
  // Arguments
  //   var hauteursArgument = [];
  //   $(".woocommerce-page .products li.product .product--arguments").each(
  //     function () {
  //       hauteursArgument.push($(this).innerHeight());
  //     }
  //   );
  //   var hauteurArgumentMax = Math.max.apply(Math, hauteursArgument);
  //   $(".woocommerce-page .products li.product .product--arguments").css(
  //     "min-height",
  //     hauteurArgumentMax
  //   );

  //Extrait;
  //   var hauteursExtrait = [];
  //   $(".woocommerce-page .products li.product .product--excerpt").each(
  //     function () {
  //       hauteursExtrait.push($(this).innerHeight());
  //     }
  //   );
  //   var hauteurExtraitMax = Math.max.apply(Math, hauteursExtrait);
  //   $(".woocommerce-page .products li.product .product--excerpt").css(
  //     "min-height",
  //     hauteurExtraitMax
  //   );
}

function imgBack() {
    $(".backImg").each(function () {
        var urlImgCurrent = $(this).children("img").attr("src");
        if (
            urlImgCurrent &&
            urlImgCurrent != undefined &&
            $(this).children("img").length
        ) {
            $(this).attr(
                "style",
                "background-image:url(" +
                urlImgCurrent +
                "); background-position: 50% 50%; background-size: cover; background-repeat: no-repeat;"
            );
            $(this).find("img").remove();
        }
    });
}

function sliders() {
    $(".bloc--carrousel").not(".slick-initialized").slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: "slider",
        responsive: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerPadding: "18px",
        prevArrow:
        '<span class="slide-prev"><i class="fa-regular fa-arrow-left"></i></span>',
        nextArrow:
        '<span class="slide-next"><i class="fa-regular fa-arrow-right"></i></span>',
    });

    $(".products")
        .not(".slick-initialized")
        .slick({
        autoplay: false,
        autoplaySpeed: 5000,
        respondTo: "slider",
        responsive: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        centerMode: true,
        centerPadding: "8px",
        prevArrow:
            '<span class="slide-prev"><i class="fa-regular fa-arrow-left"></i></span>',
        nextArrow:
            '<span class="slide-next"><i class="fa-regular fa-arrow-right"></i></span>',
        responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                initialSlide: 2,
            },
            },
            {
            breakpoint: 850,
            settings: {
                slidesToShow: 2,
                initialSlide: 2,
            },
            },
            {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
                initialSlide: 1,
            },
            },
        ],
        });

    $(".bloc--introduction-sliders").not(".slick-initialized").slick({
        autoplay: true,
        autoplaySpeed: 5000,
        respondTo: "slider",
        responsive: true,
        arrows: false,
        dots: false,
        fade: true,
        infinite: true,
        pauseOnFocus: false,
        centerPadding: "18px",
        prevArrow:
        '<span class="slide-prev"><i class="fa-regular fa-arrow-left"></i></span>',
        nextArrow:
        '<span class="slide-next"><i class="fa-regular fa-arrow-right"></i></span>',
    });
}

function sliderNumbers() {
  if ($(window).width() < 1010) {
    $(".bloc--numbers").not(".slick-initialized").slick({
      autoplay: true,
      autoplaySpeed: 5000,
      respondTo: "slider",
      responsive: true,
      centerMode: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerPadding: "18px",
      arrows: true,
      dots: false,
      infinite: false,
      prevArrow:
        '<span class="slide-prev"><i class="fa-regular fa-arrow-left"></i></span>',
      nextArrow:
        '<span class="slide-next"><i class="fa-regular fa-arrow-right"></i></span>',
    });

    $(".bloc--numbers").slick("slickRemove", 5);
  }
}

function accordeon() {
  $(".bloc--accordeon .accordeon--content").hide();

  // On sélectionne tous les items de liste portant la classe "toggleSubMenu"
  // et on remplace l'élément span qu'ils contiennent par un lien :
  $(".accordeon .accordeon--title").on("click", function () {
    // Si le sous-menu était déjà ouvert, on le referme :
    if ($(this).next(".accordeon--content:visible").length != 0) {
      $(this).next(".accordeon--content").slideUp("normal");
      $(this).removeClass("open");
    }
    // Si le sous-menu est caché, on ferme les autres et on l'affiche :
    else {
      $(".accordeon .accordeon--content").slideUp("normal");
      $(".accordeon .accordeon--title").removeClass("open");
      $(this).next(".accordeon--content").slideDown("normal");
      $(this).addClass("open");
    }
    // On empêche le navigateur de suivre le lien :
    return false;
  });
}

function animationScroll() {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause",
    });

    let scrollAnimFade = gsap.utils.toArray(".elem--anim-opacity");
    scrollAnimFade.forEach((item) => {
        gsap.from(item, {
        scrollTrigger: {
            trigger: item,
            start: "top 90%",
            end: "top 75%",
            markers: false,
            scrub: true,
        },
        opacity: 0,
        duration: 0.5,
        });
    });

    if ($(".title--anim").length != 0) {
        let scrollAnimTitle = gsap.utils.toArray(".title--anim");
        scrollAnimTitle.forEach((item) => {
            gsap.from(item, {
                scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true,
                },
                opacity: 0,
                duration: 0.5,
            });
        });
    }

    if ($(".elem--anim-top").length != 0) {
        let scrollAnimtop = gsap.utils.toArray(".elem--anim-top");
        scrollAnimtop.forEach((item) => {
            gsap.from(item, {
                    scrollTrigger: {
                    trigger: item,
                    start: "top 90%",
                    end: "top 75%",
                    markers: false,
                    scrub: true,
                },
                opacity: 0,
                y: 90,
                duration: 0.5,
            });
        });
    }

    if ($(".elem--anim-enter").length != 0) {
        gsap.to(".elem--anim-enter", 0.5, { autoAlpha: 1, y: 0 });
    }
}

function onglets() {
    var hauteurMax = $('.tabs > ul').innerHeight();
    $(".tab-content").each(function () {
        // if ($(this).innerHeight() >= hauteurMax) {
        //     hauteurMax = $(this).innerHeight();
        //     $(".tab-content").css("min-height", hauteurMax);
        // }

        $(".tab-content").css("min-height", hauteurMax);
    });

    $(".tabs .tab").on("click", function (e) {
        e.preventDefault();

        if (!$(this).parent("li").hasClass("active")) {
        var tab = $(this).attr("data");

        /* On suprime la class active de tous les contenus */
        $(".tab-content").removeClass("active");
        $(".tab-content").hide().delay(200);

        /* On supprime la classe pour tous les liens */
        $(".tabs li").removeClass("active");

        /* On ajoute la classe active à l'onglet qui doit être visible */
        $(tab).addClass("active");
        $(tab).show();

        /* On ajoute la classe pour le lien sur lequel l'internaute vient de cliquer */
        $(this).parent("li").addClass("active");

        return false;
        }
    });
}
